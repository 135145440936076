import React, { useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Background from "../img/about.svg";
import onboard from "../img/onboard.svg";
import AboutImg from "../img/about.svg";
import design from "../img/design.svg";
import listings from "../img/listings.svg";
import { FaChevronDown } from "react-icons/fa";
import PricingTable from "../components/PricingTable";
import ScrollToHash from "../components/ScrollToHash";

const Website = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <ScrollToHash />
      <div className="relative mb-10">
        <img
          src={Background}
          className="absolute inset-0 object-cover w-full h-full"
          alt="Background"
        />
        <div className="absolute inset-0 object-cover w-full h-full"></div>
        <div className="relative website-hero">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 lg:mb-0">
            <div className="flex flex-col justify-center items-center mt-10 lg:mt-20 text-white">
              <div className="space-y-7 mb-10 flex flex-col justify-center items-center">
                <div className="lg:mt-16 max-w-3xl">
                  <h1 className="capitalize text-center">
                    Websites that{" "}
                    <span className="text-main">convert leads</span> to Clients{" "}
                  </h1>
                </div>
                <div>
                  <p className="pb-3 text-lg text-white max-w-2xl text-center">
                    Maximize your listings potential with our high-conversion
                    real estate websites designed to attract and engage buyers.
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <NavLink to="#start">
                    <button className="cta !bg-main flex items-center gap-2.5">
                      <span className="text-lg">Get Started</span>
                      <span className="text-lg">
                        <FaChevronDown />
                      </span>
                    </button>
                  </NavLink>
                  <a
                    href="http://demorealestate.nam.na"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="justify-center py-2 w-full text-center flex items-center">
                      <span className="font-semibold">View Demo</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 md:py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 relative">
        <div className="absolute -top-[100px]" id="start"></div>

        <div className="grid md:gap-10 lg:grid-cols-2 py-10 border-b-2">
          <div className="flex flex-col justify-center text-center lg:text-left ">
            <div className="w-full mb-10 md:mb-0 md:mx-auto">
              <h1 className="mb-6 leading-none text-dark md:mx-auto text-left capitalize ">
                Add Properties <br /> listings
              </h1>
              <p className="text-light max-w-lg font-medium text-left ">
                Easily add and manage property listings with our intuitive
                interface.
              </p>
            </div>
          </div>
          <div>
            <img src={listings} alt="Listings" />
          </div>
        </div>

        <div className="grid md:gap-10 lg:grid-cols-2 py-10">
          <div className="order-2 md:order-1">
            <img src={design} alt="Design" />
          </div>
          <div className="flex flex-col justify-center text-center lg:text-left order-1 md:order-2">
            <div className="w-full mb-10 md:mb-0 md:mx-auto">
              <h1 className="mb-6 leading-none text-dark md:mx-auto text-left capitalize ">
                Tailored <br /> Design
              </h1>
              <p className="text-light max-w-lg font-medium text-left ">
                Each website is meticulously crafted to align perfectly with
                your brand identity. Whether you're a seasoned agency or a
                rising star, our sites empower you to stand out.
              </p>
            </div>
          </div>
        </div>

        <div className="py-16 mx-auto sm:max-w-xl md:max-w-full lg:py-20 h-fit">
          <div className="flex flex-col overflow-hidden bg-white border-2 rounded-[40px] lg:flex-row sm:mx-auto">
            <div className="relative lg:w-1/2">
              <img
                src={AboutImg}
                alt="About"
                className="object-cover w-full lg:absolute h-80 lg:h-full"
              />
              <svg
                className="absolute top-0 -right-1 hidden h-full text-white lg:inline-block"
                viewBox="0 0 20 104"
                fill="currentColor"
              >
                <polygon points="17.3036738 5.68434189e-14 20 5.68434189e-14 20 104 0.824555778 104" />
              </svg>
            </div>
            <div className="flex flex-col justify-center p-8 bg-white lg:p-16 lg:pl-10 lg:w-1/2">
              <p className="text-main uppercase font-bold text-lg lg:text-left text-center">
                limited time offer
              </p>
              <h2 className="mb-5 my-3 text-dark text-3xl lg:text-5xl text-center lg:text-left">
                Get Started Now!
              </h2>
              <div className="flex w-full">
                <NavLink to="#pricing" className="w-full">
                  <button className="cta w-full lg:w-fit !bg-main flex flex-col justify-center items-center">
                    <span className="text-lg">Packages & Pricing</span>
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="grid md:gap-10 lg:grid-cols-2 py-10 border-b-0">
          <div className="flex flex-col justify-center text-center lg:text-left">
            <div className="w-full mb-10 md:mb-0 md:mx-auto">
              <h1 className="mb-6 leading-none text-dark md:mx-auto text-left capitalize ">
                Swift <br /> Onboarding
              </h1>
              <p className="text-light max-w-lg font-medium text-left ">
                Get up and running quickly with our streamlined onboarding
                process.
              </p>
            </div>
          </div>
          <div>
            <img src={onboard} alt="Onboard" />
          </div>
        </div>
      </div>

      <div
        className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 lg:mt-0"
        id="pricing"
      >
        <div className="grid gap-10 lg:grid-cols-3 sm:grid-cols-1 relative">
          <div className="left-4 absolute w-4 flex lg:hidden rounded-full -z-10 bg-gray-200 h-full"></div>
          <div>
            <div className="flex items-center gap-5 mb-6">
              <div className="rounded-full w-12 h-12 bg-dark text-white flex items-center justify-center">
                <p className="text-2xl font-bold">1</p>
              </div>
              <p className="text-3xl font-bold text-dark">Onboarding</p>
            </div>
            <p className="text-light font-medium ml-14 pl-3">
              The onboarding phase involves establishing a clear understanding
              of your goals and expectations.
            </p>
          </div>

          <div>
            <div className="flex items-center gap-5 mb-6">
              <div className="rounded-full w-12 h-12 bg-dark text-white flex items-center justify-center">
                <p className="text-2xl font-bold">2</p>
              </div>
              <p className="text-3xl font-bold text-dark">Development</p>
            </div>
            <p className="text-light font-medium ml-14 pl-3">
              The development phase is where the actual creation of the website
              takes place.
            </p>
          </div>
          <div>
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center gap-5">
                <div className="rounded-full w-12 h-12 bg-dark text-white flex items-center justify-center">
                  <p className="text-2xl font-bold">3</p>
                </div>
                <p className="text-3xl font-bold text-dark">Success</p>
              </div>
              <div className="rounded-full w-12 h-12 bg-main text-white items-center justify-center hidden lg:flex">
                <svg
                  className="w-8 text-white"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <polyline
                    fill="none"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="6,12 10,16 18,8"
                  />
                </svg>
              </div>
            </div>
            <p className="text-light font-medium ml-14 pl-3">
              The success phase includes setting up hosting, configuring the
              domain, and launching the website.
            </p>
          </div>
        </div>
      </div>
      <PricingTable />
    </>
  );
};

export default Website;
