import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../img/white.svg";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <div className="bg-[#191919] mt-[50px]">
      <footer>
        <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="sm:col-span-2">
            <NavLink to="/">
              <img src={Logo} width={144} alt="" />
            </NavLink>
            <div className="mt-6 lg:max-w-sm">
              <p className="text-sm">Grow Your Real Estate Business</p>
            </div>
          </div>
          <div className="space-y-2 text-sm">
            <p className="text-base font-bold tracking-wide text-white">
              Contacts
            </p>
            <div className="flex">
              <p className="mr-1">Phone:</p>
              <a
                href="tel:+264 85 287 8236"
                aria-label="Our phone"
                title="Our phone"
                className="transition-colors duration-300 text-main font-semibold "
              >
                +264 85 287 8236
              </a>
            </div>
            <div className="flex">
              <p className="mr-1">Email:</p>
              <a
                href="mailto:contact@estatekit.co"
                aria-label="Our email"
                title="Our email"
                className="transition-colors duration-300 text-main font-semibold "
              >
                contact@estatekit.co
              </a>
            </div>
            <div className="flex">
              <p className="mr-1">Address:</p>
              <a
                href="https://www.google.com/maps"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our address"
                title="Our address"
                className="transition-colors duration-300 text-main font-semibold "
              >
                Swakopmund, Namibia
              </a>
            </div>
          </div>
          <div>
            <span className="text-base font-bold tracking-wide text-white">
              Social
            </span>
            <div className="flex items-center mt-1 space-x-3">
              <a
                target="_blank"
                href="https://www.instagram.com/estatekit.co/"
                className="text-white transition-colors duration-300 hover:text-main"
              >
                <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                  <circle cx="15" cy="15" r="4" />
                  <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/people/EstateKit/61558385861304/"
                target="_blank"
                className="text-white transition-colors duration-300 hover:text-main"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                </svg>
              </a>
            </div>
            <p className="mt-4 text-sm text-white">Follow our socials!</p>
          </div>
        </div>
        <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t border-white/10 lg:flex-row">
          <p className="text-sm">All rights reserved. All wrongs reversed.</p>
          <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
            <li>
              <a
                href="/"
                className="text-sm transition-colors duration-300 hover:text-main"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="/"
                className="text-sm transition-colors duration-300 hover:text-main"
              >
                Terms &amp; Conditions
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
