import React, { useState, useEffect } from "react";
import Background from "../img/bg.jpg";
import Slant from "../img/slant.svg";
import Contact from "../components/Contact";
import ScrollToHash from "../components/ScrollToHash";
import Info from "../components/Info";

const Demo = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    product: "",
    plan: "",
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [currency, setCurrency] = useState("NAD");
  const [exchangeRate, setExchangeRate] = useState(1);
  const [country, setCountry] = useState("");
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    const fetchLocationAndExchangeRate = async () => {
      try {
        const [locationResponse, exchangeResponse] = await Promise.all([
          fetch("https://ipapi.co/json/"),
          fetch("https://api.exchangerate-api.com/v4/latest/NAD"),
        ]);

        const locationData = await locationResponse.json();
        const exchangeData = await exchangeResponse.json();

        const userCurrency = locationData.currency;
        setCurrency(userCurrency);
        setCountry(locationData.country);
        setIpAddress(locationData.ip);
        setFormData((prevData) => ({
          ...prevData,
          phone: `${locationData.country_calling_code} `,
        }));

        setExchangeRate(exchangeData.rates[userCurrency] || 1);
      } catch (error) {
        console.error("Error fetching location or exchange rate:", error);
      }
    };

    fetchLocationAndExchangeRate();
  }, []);

  useEffect(() => {
    // Calculate total price based on product and plan selections
    let price = 0;
    if (formData.product === "AI Co-Agent") {
      price += formData.plan === "Basic" ? 849 : 1499; // Adjust prices as needed
    } else if (formData.product === "Real Estate Websites") {
      price += formData.plan === "Basic" ? 499 : 599; // Adjust prices as needed
    }
    setTotalPrice(price * exchangeRate); // Convert price based on exchange rate
  }, [formData.product, formData.plan, exchangeRate]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let deviceInfo = "Unknown";
    if (/Android/i.test(navigator.userAgent)) {
      deviceInfo = "Mobile - Android";
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      deviceInfo = "Mobile - iOS";
    } else if (/Windows/i.test(navigator.userAgent)) {
      deviceInfo = "Windows";
    } else if (/Mac/i.test(navigator.userAgent)) {
      deviceInfo = "Mac";
    }

    if (window.fbq) {
      window.fbq("track", "Lead");
    } else {
      console.error("Facebook Pixel not initialized.");
    }

    const webhookUrl =
      "https://discord.com/api/webhooks/1258076447608803499/YHuitgNG6L_6xm0gMaTso75jVB1MJKkUq4c_kDYk8bV0yJHh33zzrg2DJp09BXKWtWwf";

    const fetchIpAndCountry = async () => {
      try {
        const [ipResponse, countryResponse, countryCodeResponse] = await Promise.all([
          fetch("https://api64.ipify.org?format=json"),
          fetch(`https://ipapi.co/${ipAddress}/country_name/`),
          fetch(`https://ipapi.co/${ipAddress}/country/`),
        ]);

        const ipData = await ipResponse.json();
        const countryName = await countryResponse.text();
        const countryCode = await countryCodeResponse.text();

        return { ipAddress: ipData.ip, country: countryName, countryCode };
      } catch (error) {
        console.error("Error fetching IP address or country:", error);
        return { ipAddress: "Unknown", country: "Unknown", countryCode: "Unknown" };
      }
    };

    const { ipAddress, country, countryCode } = await fetchIpAndCountry();

    // Construct payload for Discord webhook
    const payload = {
      embeds: [
        {
          title: "New Form Submission :clipboard:",
          description: "A new form submission has been received:",
          color: 12455, // Light Blue
          fields: [
            { name: ":person_bald: Name", value: formData.name },
            { name: ":envelope: Email", value: formData.email },
            { name: ":telephone: Phone", value: formData.phone },
            { name: ":office: Company Name", value: formData.company },
            { name: ":computer: Product", value: formData.product },
            { name: ":chart_with_upwards_trend: Plan", value: formData.plan },
            { name: ":iphone: Device", value: deviceInfo },
            {
              name: `:flag_${countryCode.toLowerCase()}: Country`,
              value: country,
            },
            { name: ":email: Email Status", value: "Pending" }, // Initialize with pending status
          ],
          footer: { text: "Form submission from EstateKit" },
          timestamp: new Date(),
        },
      ],
    };

    let emailSentStatus = "Unsuccessful";

    const formDataWithTotal = {
      ...formData,
      total: totalPrice,
      device: deviceInfo,
      ip: ipAddress,
    };

    try {
      const response = await fetch(
        "https://6dca-197-188-160-60.ngrok-free.app/submit-form",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formDataWithTotal),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form.");
      }

      emailSentStatus = "Success"; // Update the status if email sent successfully
      console.log("Form data sent successfully.");
      setSubmitted(true);
      setError(null);
      window.scrollTo(0, 0); // Scroll to the top after successful submission
    } catch (error) {
      emailSentStatus = "Unsuccessful"; // Update the status if email sending failed
      setSubmitted(true);
      console.error("Error submitting form:", error);
      setError("Failed to submit form.");
    }

    // Update the payload with the final email status
    payload.embeds[0].fields.find(
      (field) => field.name === ":email: Email Status"
    ).value = emailSentStatus;

    try {
      const response = await fetch(webhookUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to send form data to Discord webhook.");
      }

      console.log("Form data sent to Discord webhook successfully.");
    } catch (error) {
      console.error("Error sending form data to Discord webhook:", error);
    }
  };

  // Function to get currency symbol based on currency code
  const getCurrencySymbol = (currency) => {
    const symbols = {
      USD: "$",
      EUR: "€",
      GBP: "£",
      NAD: "N$",
      // Add more currency symbols as needed
    };
    return symbols[currency] || currency;
  };

  return (
    <>
      <ScrollToHash />
      <div className="relative" id="booking">
        <img
          src={Background}
          className="absolute inset-0 object-cover w-full h-full"
          alt=""
        />
        <div className="absolute inset-0 bg-black opacity-90"></div>
        <div className="relative ">
          <img
            src={Slant}
            className="absolute bottom-0 z-20"
            draggable="false"
            alt=""
          />
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 lg:max-h-[800px]">
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-10 mt-20 text-white">
              <div className="rounded-[40px] shadow bg-[#F6FCF6] p-10">
                {submitted ? (
                  <div>
                    <h2 className="text-5xl font-extrabold text-dark text-center">
                      Thank you for your submission!
                    </h2>
                    <p className="text-lg font-semibold text-center text-main mt-10">
                      Please check your inbox for our email. Also, check your
                      spam folder.
                    </p>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="space-y-6">
                      <h2 className="text-4xl font-extrabold  text-dark capitalize">
                        Let's get you onboard!
                      </h2>
                      <div className="flex flex-col space-y-4">
                        <div className="flex flex-col lg:flex-row w-full gap-5">
                          <div className="w-full">
                            <label
                              htmlFor="name"
                              className="block pl-3 font-semibold text-dark"
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Your Name"
                              required
                              onChange={handleInputChange}
                              value={formData.name}
                              className="bg-white w-full h-11 border-2 rounded-xl mt-2 focus:!border-main outline-none pl-3 font-medium text-dark"
                            />
                          </div>
                          <div className="w-full">
                            <label
                              htmlFor="email"
                              className="block pl-3 font-semibold text-dark"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Your Email Address"
                              required
                              onChange={handleInputChange}
                              value={formData.email}
                              className="bg-white w-full h-11 border-2 rounded-xl mt-2 focus:!border-main outline-none pl-3 font-medium text-dark"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col lg:flex-row w-full gap-5">
                          <div className="w-full">
                            <label
                              htmlFor="company"
                              className="block pl-3 font-semibold text-dark"
                            >
                              Company Name
                            </label>
                            <input
                              type="text"
                              name="company"
                              id="company"
                              placeholder="Your Company Name"
                              required
                              onChange={handleInputChange}
                              value={formData.company}
                              className="bg-white w-full h-11 border-2 rounded-xl mt-2 focus:!border-main outline-none pl-3 font-medium text-dark"
                            />
                          </div>
                          <div className="w-full">
                            <label
                              htmlFor="phone"
                              className="block pl-3 font-semibold text-dark"
                            >
                              Phone Number
                            </label>
                            <input
                              type="tel"
                              name="phone"
                              id="phone"
                              placeholder="Your Phone Number"
                              required
                              onChange={handleInputChange}
                              value={formData.phone}
                              className="bg-white w-full h-11 border-2 rounded-xl mt-2 focus:!border-main outline-none pl-3 font-medium text-dark"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col lg:flex-row w-full gap-5">
                          <div className="relative w-full">
                            <label
                              htmlFor="product"
                              className="block pl-3 font-semibold text-dark"
                            >
                              Product
                            </label>
                            <select
                              name="product"
                              value={formData.product}
                              onChange={handleInputChange}
                              className="bg-white w-full h-11 border-2 rounded-xl mt-2 focus:!border-main outline-none pl-3 font-medium text-dark"
                              required
                            >
                              <option value="">Select Product</option>
                              <option value="AI Co-Agent">AI Co-Agent</option>
                              <option value="Real Estate Websites">
                                Real Estate Websites
                              </option>
                            </select>
                          </div>
                          <div className="relative w-full">
                            <label
                              htmlFor="plan"
                              className="block pl-3 font-semibold text-dark"
                            >
                              Plan
                            </label>
                            <select
                              name="plan"
                              value={formData.plan}
                              onChange={handleInputChange}
                              className="bg-white w-full h-11 border-2 rounded-xl mt-2 focus:!border-main outline-none pl-3 font-medium text-dark"
                              required
                            >
                              <option value="">Select Plan</option>
                              <option value="Basic">Basic</option>
                              <option value="Premium">Premium</option>
                            </select>
                          </div>
                        </div>
                        <div className="text-lg font-bold text-dark">
                          Total: {getCurrencySymbol(currency)}{" "}
                          {totalPrice.toFixed(2)}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="cta flex w-full items-center text-center"
                      >
                        <span className="text-lg w-full">Submit</span>
                      </button>
                    </div>
                  </form>
                )}
              </div>
              <Info />
            </div>
          </div>
        </div>
      </div>
      <Contact to="#booking" />
    </>
  );
};

export default Demo;
