import React, { useState, useEffect } from "react";
import { IoMdDownload } from "react-icons/io";
import guide from "../img/book.svg";
import Logo from "../img/white.svg";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Magnet = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setModalOpen(true);
  //   }, 5000); // 20000 milliseconds = 20 seconds

  //   return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  // }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const webhookUrl =
      "https://discord.com/api/webhooks/1258023717896982578/NFXPkWz-yU_eNtyyHVT9nZaK1x3lfAkrac5JpmqbDG6xei8hbZBmPFEb0cZ7-nsrRo3H";

    // Construct payload for Discord webhook
    const payload = {
      embeds: [
        {
          title: "You Caught Another Lead! :jellyfish:",
          description: "A new form submission has been received:",
          color: 12455, // Light Blue
          fields: [
            {
              name: ":person_bald: Name",
              value: formData.name,
            },
            {
              name: ":envelope: Email",
              value: formData.email,
            },
            {
              name: ":telephone: Phone",
              value: formData.phone,
            },
          ],
          footer: {
            text: "Form submission from https://estatekit.co",
          },
          timestamp: new Date(),
        },
      ],
    };

    try {
      const response = await fetch(webhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to send form data to Discord webhook.");
      }

      console.log("Form data sent to Discord webhook successfully.");
      setSubmitted(true); // Show thank you message
      setError(null);

      // Trigger the file download
      triggerDownload();
    } catch (error) {
      console.error("Error sending form data to Discord webhook:", error);
      setError("Failed to send form data to Discord webhook.");
    }
  };

  const triggerDownload = () => {
    const link = document.createElement("a");
    link.href = "/guide.pdf"; // Replace with the actual file path
    link.download = "guide.pdf"; // Set the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSubmitted(false); // Reset submitted state
    setFormData({
      name: "",
      email: "",
      phone: "",
    });
    setError(null); // Clear any previous error
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      closeModal();
    }
  };

  return (
    <div className="px-4 border-y-2 bg-white py-10">
      <div className="flex w-full rounded-3xl overflow-hidden mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 gap-10">
        <div className="w-full text-dark flex items-center gap-10 flex-col lg:flex-row">
          <div>
            <img src={guide} width={340} alt="" />
          </div>
          <div>
            <p className="text-main font-bold uppercase text-lg  lg:text-left text-center">
              Download our Free Guide
            </p>
            <h2 className="text-4xl lg:text-5xl my-5 text-dark max-w-3xl lg:text-left text-center">
              Unlock a Flood of Leads In Minutes
            </h2>
            <p className="mb-5 text-dark font-medium text-lg  lg:text-left text-center">
              Click the button below to get the guide!
            </p>
            <button
              onClick={() => setModalOpen(true)}
              className="cta flex gap-3 items-center mx-auto lg:mx-0"
            >
              <span className="text-lg">
                <IoMdDownload />
              </span>
              <span className="text-lg">Download Free Guide</span>
            </button>
          </div>
        </div>
      </div>

      {/* Modal */}
      {modalOpen && (
        <div
          className="fixed inset-0 z-50 overflow-y-auto"
          onClick={handleOverlayClick}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black backdrop-blur-3xl modal-overlay opacity-70"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div className="inline-block align-bottom bg-main text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full rounded-[40px]">
              <div className="py-3 flex items-center justify-center">
                <img src={Logo} width={110} alt="" />
              </div>
              {!submitted ? (
                <form onSubmit={handleSubmit}>
                  <div className="bg-[#F6FCF6] relative p-10 rounded-t-[40px]">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 sm:mt-0 text-left">
                        <h3 className="text-2xl font-semibold text-dark ">
                          <span className="font-bold">Download The Guide</span>:
                          Unlock a Flood of Leads In Minutes
                        </h3>
                        <div className="mt-10 ">
                          <div className="flex w-full gap-5">
                            <div className="mb-4 w-full">
                              <label
                                htmlFor="name"
                                className="block pl-3 font-semibold text-dark"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Your Name"
                                required
                                onChange={handleInputChange}
                                value={formData.name}
                                className="bg-white w-full h-11 border-2 rounded-xl mt-2 focus:!border-main outline-none pl-3 font-medium text-dark"
                              />
                            </div>
                            <div className="mb-4 w-full">
                              <label
                                htmlFor="email"
                                className="block pl-3 font-semibold text-dark"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Your Email Address"
                                required
                                onChange={handleInputChange}
                                value={formData.email}
                                className="bg-white w-full h-11 border-2 rounded-xl mt-2 focus:!border-main outline-none pl-3 font-medium text-dark"
                              />
                            </div>
                          </div>
                          <div className="mb-4 w-full">
                            <label
                              htmlFor="phone"
                              className="block pl-3 font-semibold text-dark"
                            >
                              Phone
                            </label>
                            <PhoneInput
                              country={'us'}
                              value={formData.phone}
                              onChange={handlePhoneChange}
                              inputStyle={{
                                width: "100%",
                                height: "44px",
                                borderRadius: ".75rem",
                                borderColor: "#E5E7EB",
                                paddingLeft: "50px"
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-t-2 border-dashed p-5 flex flex-row-reverse gap-2 bg-white rounded-b-[40px]">
                    <button className="cta flex w-full items-center text-center">
                      <span className="text-lg w-full">Download</span>
                    </button>
                    <button
                      className="flex gap-3 cta !bg-gray-200 !text-dark w-full items-center text-center"
                      onClick={closeModal}
                    >
                      <span className="text-lg font-semibold w-full ">
                        Cancel
                      </span>
                    </button>
                  </div>
                </form>
              ) : (
                <div className="bg-[#F6FCF6] relative p-10 rounded-[40px]">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-3 text-center sm:mt-0">
                      <h3 className="text-3xl font-bold capitalize text-dark">
                        Thank you for downloading the guide!
                      </h3>
                      <p className="mt-4 text-lg text-dark font-semibold mb-20">
                        You can now access the guide by clicking the button
                        below.
                      </p>
                      <div className="border-t-2 border-dashed pt-5 flex flex-row-reverse gap-2 bg-white rounded-b-[40px]">
                        <button
                          className="cta flex w-full items-center text-center"
                          onClick={triggerDownload}
                        >
                          <span className="text-lg w-full">Download</span>
                        </button>
                        <button
                          className="flex gap-3 cta !bg-gray-200 !text-dark w-full items-center text-center"
                          onClick={closeModal}
                        >
                          <span className="text-lg font-semibold w-full ">
                            Cancel
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Magnet;
