import React from "react";
import { NavLink } from "react-router-dom";

const CtaButton = ({ to }) => {
  const getDayTwoDaysAhead = () => {
    const daysOfWeek = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    const today = new Date();
    const dayTwoDaysAhead = new Date(today);
    dayTwoDaysAhead.setDate(today.getDate() + 2);
    return daysOfWeek[dayTwoDaysAhead.getDay()];
  };

  const dayTwoDaysAhead = getDayTwoDaysAhead();

  return (
    <NavLink to={to} className="w-full">
      <button className="cta-2 flex flex-col justify-center items-center w-full lg:w-fit mx-auto">
        <span className="text-xl lg:text-2xl font-bold">Get Started Now</span>
        <span className="font-normal text-white/90 text-sm">
          Limited <span className="font-semibold">FREE TRIAL</span> Spots Left
        </span>
      </button>
    </NavLink>
  );
};

export default CtaButton;
