import React from "react";
import Hero from "./sections/Hero";
import Letter from "./sections/Letter";
import Slider from "./sections/Slider";
import Guarantee from "./sections/Guarantee";
import Magnet from "./sections/Magnet";
import Requirements from "./sections/Requirements";
import Questions from "./sections/Questions";

const Funnel = () => {
  return (
    <section className="funnel">
      <h1>THIS WON’T WORK FOR YOUR BUSINESS!</h1>
      <Hero />
      <Letter />
      <Slider />
      <Guarantee />
      <Magnet />
      <Requirements />
      <Questions />
    </section>
  );
};

export default Funnel;
