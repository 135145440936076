import React from "react";
import Websites from "../img/websites.png";
import Ai from "../img/ai.png";
import Star from "../img/star.svg";
import { PiHandTapBold } from "react-icons/pi";
import Tape from "../img/TAPE.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import Tape2 from "../img/TAPE2.svg";

const Products = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <h2 className="text-5xl my-5 text-dark max-w-3xl text-left">
        Our Products
      </h2>
      <p className="mb-5 text-dark font-medium text-lg text-left">
        Discover our featured products below!
      </p>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <NavLink to="/websites">
          <div className="rounded-[40px] website-card p-10 overflow-hidden  h-[350px] relative">
            <PiHandTapBold className="text-white text-[24px] top-6 right-6 absolute tap"/>
            <h3 className="text-[40px] font-bold text-white text-center leading-10">
              Real Estate Websites
            </h3>
            <p className="text-center text-white">
              Your Perfect Real Estate Website
            </p>
            <img src={Websites} alt="" />
          </div>
        </NavLink>
        <NavLink to="/ai">
          <div className="rounded-[40px] ai-card p-10 overflow-hidden  h-[350px] relative">
            <PiHandTapBold className="text-white text-[24px] top-6 right-6 absolute tap"/>
            <h3 className="text-[40px] font-bold text-white text-center relative  w-fit mx-auto">
              <span className="absolute text-xs !font-extrabold right-0 px-1 text-[#094B09] bg-white rounded -top-1">
                BETA
              </span>
              AI Agent
            </h3>
            <p className="text-center text-white">Your Very Own AI Co-Agent</p>
            <img
              src={Tape}
              alt=""
              className="transform scale-[2.1] bottom-40 z-20 rotate-[20deg] absolute hidden"
            />
            <img
              src={Tape2}
              alt=""
              className="transform scale-[2.1] bottom-[140px] z-10  -rotate-[16deg] absolute hidden"
            />
            <img src={Star} alt="" className="absolute top-16 lg:left-32 h-4" />
            <img src={Star} alt="" className="absolute top-32 right-32" />
            <img
              src={Ai}
              alt=""
              className="absolute left-0 bottom-0 lg:-bottom-[70px]"
            />
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default Products;
