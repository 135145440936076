import React from "react";
import Logo from "../assets/primary.svg";

const StrategyForm = () => {
  return (
    <section>
      {/* navbar */}
      <nav className="w-full h-16 shadow !bg-white flex items-center justify-center">
        <img src={Logo} width={140} />
      </nav>

      {/* progress bar */}
      <div className="w-4/12 mt-20 mx-auto relative flex items-center">
        <div className="flex items-center justify-between w-full">
          <div className="rounded-full h-8 w-8 bg-white flex items-center justify-center font-bold border-[5px] border-main">
            1
          </div>
          <div className="rounded-full h-8 w-8 bg-white flex items-center justify-center font-bold border-[5px] border-main">
            2
          </div>
          <div className="rounded-full h-8 w-8 bg-white flex items-center justify-center font-bold border-[5px] border-[#e7e7e7]">
            3
          </div>
          <div className="rounded-full h-8 w-8 bg-white flex items-center justify-center font-bold border-[5px] border-[#e7e7e7]">
            4
          </div>
          <div className="rounded-full h-8 w-8 bg-white flex items-center justify-center font-bold border-[5px] border-[#e7e7e7]">
            5
          </div>
          <div className="rounded-full h-8 w-8 bg-white flex items-center justify-center font-bold border-[5px] border-[#e7e7e7]">
            6
          </div>
        </div>
        <div className="w-[16%] h-3 bg-main rounded-full absolute -z-10"></div>
        <div className="w-full h-3 bg-[#E7E7E7] rounded-full absolute -z-20"></div>
      </div>

      {/* question */}
      <div className="mt-20 text-center text-dark">
        <h2>What country are you based in?</h2>
      </div>

      {/* input */}
      <div className="flex flex-col gap-5 items-center justify-center">
        <input
          type="text"
          placeholder="Enter your first name"
          className="border-2 rounded-xl font-medium focus:border-main w mt-10 mx-auto p-5 text-2xl w-[500px]"
        />
        <button className="bg-main text-2xl font-medium text-white rounded-xl p-5">
          Continue
        </button>
      </div>
    </section>
  );
};

export default StrategyForm;
