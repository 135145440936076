import React from "react";
import CtaButton from "./CtaButton";
import { CgCloseO } from "react-icons/cg";
import ScrollToHash from "./ScrollToHash";
const Offer = () => {
  return (
    <div
      id="about"
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
    >
      <ScrollToHash />
      <div className="grid gap-5 row-gap-10 lg:grid-cols-2">
        <div className="flex flex-col justify-center text-center lg:text-left">
          <div className="max-w-xl space-y-5 mb-6">
            <p className="text-dark font-medium text-lg">What we offer</p>
            <h2 className="max-w-lg text-main uppercase">Website Design</h2>
            <p className="text-dark font-medium text-lg">
              Design, Just Design.
              <br />
              <br />
              We specialize in one thing and we excel at it with unmatched
              dedication.
            </p>
            <div>
              <CtaButton to="book-a-demo" />
            </div>
          </div>
        </div>
        <div>
          <p className="text-dark font-extrabold text-xl mb-5 text-center lg:text-left">
            MASTERY. DEMANDS. FOCUS.
          </p>
          <p className="text-dark text-2xl text-center lg:text-left">
            We don't offer any other services except for{" "}
            <span className="italic">Real Estate Websites...</span>
          </p>
          <div className="flex flex-col gap-3 mt-5">
            <div className="flex items-center gap-3 bg-white p-3 border-2 rounded-2xl">
              <CgCloseO className="text-2xl text-red-400" />
              <p className="text-light uppercase font-semibold text-xl">
                social media management
              </p>
            </div>
            <div className="flex items-center gap-3 bg-white p-3 border-2 rounded-2xl">
              <CgCloseO className="text-2xl text-red-400" />
              <p className="text-light uppercase font-semibold text-xl">
                instagram growth
              </p>
            </div>
            <div className="flex items-center gap-3 bg-white p-3 border-2 rounded-2xl">
              <CgCloseO className="text-2xl text-red-400" />
              <p className="text-light uppercase font-semibold text-xl">
                email marketing
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
