import { GoCheckCircleFill } from "react-icons/go";
import Highlight from "../img/highlight.svg";
const Info = () => {
  return (
    <div className="space-y-7 my-10">
    <div>
      <span className="text-main text-xl uppercase font-bold ">
        Don't Miss Out!
      </span>
      <h2 className="capitalize leading-[60px] ">
        Get Started Now!
        <span className="relative text-">
          <img
            width={200}
            src={Highlight}
            className="absolute right-0 -bottom-1"
            alt=""
          />
        </span>
      </h2>
    </div>
    <div>
      <p className="pb-3 text-base md:text-lg text-white/80 leading-[30px]">
        Fill out the form to get your free demo today and let us
        help you unlock more leads and run a successful business.
        Time slots are limited, so act fast!
      </p>
    </div>
    <div>
      <span className="text-main text-xl uppercase font-bold">
        THIS DEMO IS PERFECT FOR:
      </span>
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-3">
          <GoCheckCircleFill className="text-main min-w-6 h-5" />
          <p className="pt-3 text-base md:text-lg text-white/80 leading-[30px]">
            Real estate businesses looking for a comprehensive
            digital solution to
            <span className="font-bold">
              {" "}
              boost their online presence.
            </span>
          </p>
        </div>
        <div className="flex items-center gap-3">
          <GoCheckCircleFill className="text-main min-w-6 h-5" />
          <p className="pt-3 text-base md:text-lg text-white/80 leading-[30px]">
            Agents wanting to streamline their operations with
            <span className="font-bold">
              {" "}
              cutting-edge AI tools
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Info