import React from "react";

const Steps = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 lg:mt-0">
      <h2 className="text-5xl my-5 text-dark max-w-3xl text-left">
        How It Works
      </h2>
      <p className="mb-5 text-dark font-medium text-lg text-left">
      Achieving your perfect website is straightforward with our three-step process:
      </p>
      <div className="grid gap-10 lg:grid-cols-3 sm:grid-cols-1 relative">
        <div className=" left-4 absolute w-4 flex lg:hidden rounded-full -z-10 bg-gray-200 h-full"></div>

        <div>
          <div className="flex items-center gap-5 mb-6">
            <div className="rounded-full w-12 h-12 bg-main text-white flex items-center justify-center">
              <p className="text-2xl font-bold">1</p>
            </div>
            <p className="text-3xl font-bold text-dark">Product</p>
          </div>
          <p className="text-light font-medium ml-14 pl-3">
          Discover our range of products tailored to your needs.
          </p>
        </div>

        <div>
          <div className="flex items-center gap-5 mb-6">
            <div className="rounded-full w-12 h-12 bg-main text-white flex items-center justify-center">
              <p className="text-2xl font-bold">2</p>
            </div>
            <p className="text-3xl font-bold text-dark">Plan</p>
          </div>
          <p className="text-light font-medium ml-14 pl-3">
          Choose the best plan that aligns with your goals and budget.
          </p>
        </div>
        <div>
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-5">
              <div className="rounded-full w-12 h-12 bg-main text-white flex items-center justify-center">
                <p className="text-2xl font-bold">3</p>
              </div>
              <p className="text-3xl font-bold text-dark">Development</p>
            </div>
            <div className="rounded-full w-12 h-12 bg-green-500 text-white  items-center justify-center hidden lg:flex">
              <svg
                className="w-8 text-white"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <polyline
                  fill="none"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  points="6,12 10,16 18,8"
                />
              </svg>
            </div>
          </div>
          <p className="text-light font-medium ml-14 pl-3">
          Watch your website come to life with our expert development team.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Steps;
