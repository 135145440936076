import React from "react";
import { useEffect, useState } from "react";
import CtaButton from "./CtaButton";
import ScrollToHash from "./ScrollToHash";

const Contact = ({ to }) => {
  const [navClick, setNavClick] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navClick]);

  return (
    <div
      id="contact"
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
    >
      <div className="max-w-screen-sm sm:text-center sm:mx-auto">
        <h2 className="mb-4 text-dark sm:leading-none capitalize text-center ">
          Have an inquiry?
        </h2>
        <p className="text-base text-dark md:text-lg font-medium sm:px-4 mb-16 text-center">
          If you have a general inquiry and would like to speak to our expert
          team, you can contact us via email at:{" "}
          <span className="text-main font-semibold">contact@estatekit.co</span>
        </p>
        <div className="flex items-center justify-center">
          <CtaButton to={to} onClick={() => setNavClick(!navClick)}/>
        </div>
      </div>
      <ScrollToHash />
    </div>
  );
};

export default Contact;
