import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { GoCheckCircleFill } from "react-icons/go";
import { BiSolidAlarm } from "react-icons/bi";
import { BsStars, BsCheck } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
const AiPricingTable = () => {
  const [prices, setPrices] = useState({
    basic: 499,
    premium: 599,
  });
  const [currency, setCurrency] = useState("NAD");
  const [exchangeRate, setExchangeRate] = useState(1);

  useEffect(() => {
    // Fetch the user's currency based on their location
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((locationData) => {
        const userCurrency = locationData.currency; // Get currency from the location data
        setCurrency(userCurrency);

        // Fetch the exchange rate
        fetch(`https://api.exchangerate-api.com/v4/latest/NAD`)
          .then((response) => response.json())
          .then((exchangeData) => {
            setExchangeRate(exchangeData.rates[userCurrency] || 1);
          });
      })
      .catch((error) => {
        console.error("Error fetching user location or exchange rates:", error);
      });
  }, []);

  const convertPrice = (price) => price * exchangeRate;

  const getCurrencySymbol = (currency) => {
    const symbols = {
      USD: "$",
      EUR: "€",
      GBP: "£",
      NAD: "N$",
      ZAR: "R$",
    };
    return symbols[currency] || currency;
  };

  return (
    <div class="relative w-full h-full" id="pricing">
      <div class="absolute hidden w-full bg-gray-50 lg:block h-96" />
      <div class="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="grid gap-10 md:grid-cols-3 sm:mx-auto items-start">
          <div className="py-8">
            <p className="text-main font-bold text-lg text-left uppercase">
              what are you waiting for
            </p>
            <div className="my-4 text-5xl font-extrabold relative text-dark">
              Pick A Plan
            </div>
            <div className="flex flex-col gap-3 ">
              <div className="flex items-center gap-3">
                <GoCheckCircleFill className="text-main min-w-6 h-5" />
                <p className="text-base md:text-lg text-dark">
                  <span className="font-semibold">
                    Build Trust & Credibility
                  </span>
                </p>
              </div>
              <div className="flex items-center gap-3">
                <GoCheckCircleFill className="text-main min-w-6 h-5" />
                <p className="text-base md:text-lg text-dark">
                  <span className="font-semibold">Reach Potential Clients</span>
                </p>
              </div>
              <div className="flex items-center gap-3">
                <GoCheckCircleFill className="text-main min-w-6 h-5" />
                <p className="text-base md:text-lg text-dark">
                  <span className="font-semibold">& So Much More</span>
                </p>
              </div>
            </div>
            <a
              href="http://demorealestate.nam.na"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="text-main py-2 my-4">
                <span className="text-lg font-semibold flex items-center gap-3">
                  View Demo <FaArrowRightLong />
                </span>
              </button>
            </a>
          </div>
          <div>
            <div class="flex flex-col justify-between p-8 bg-white border-2 rounded-3xl sm:items-center h-fit gap-5">
              <div className="text-center">
                <div className="text-lg font-bold text-dark">Basic</div>
                <div className="flex items-center justify-center mt-2">
                  <div className="mr-1 text-5xl font-extrabold relative text-dark">
                    {getCurrencySymbol(currency)}

                    {convertPrice(prices.basic).toFixed(0)}
                  </div>
                  <div className="text-light font-medium">/ mo</div>
                </div>
                <hr className="w-full my-5 border " />
                <div className="mt-2 space-y-3 text-left">
                  <div className="text-dark font-bold flex items-center gap-1">
                    <BsCheck className="text-2xl text-main" />
                    <div>Mortgage Calculator</div>
                  </div>
                  <div className="flex items-center gap-1 text-dark font-bold">
                    <BsCheck className="text-2xl text-main" />
                    <div>No Set Up Fee</div>
                  </div>
                  <div className="flex items-center gap-1 text-dark font-bold">
                    <BsCheck className="text-2xl text-main" />
                    <div>3 x Emails (500MB)</div>
                  </div>
                  <div className="flex items-center gap-1 text-dark font-bold">
                    <BsCheck className="text-2xl text-main" />
                    <div>Up to 3 Agents</div>
                  </div>
                  <div className="flex items-center gap-1 text-dark font-bold">
                    <BsCheck className="text-2xl text-main" />
                    <div>50 Listings</div>
                  </div>
                  <div className="flex items-center gap-1 text-light font-medium">
                    <IoClose className="text-2xl text-red-300" />
                    <div>Social Media Integration</div>
                  </div>
                  <div className="flex items-center gap-1 text-light font-medium">
                    <IoClose className="text-2xl text-red-300" />
                    <div>Compare Properties</div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <NavLink to="/get-started">
                  <button className="cta-3 !border-main !text-main hover:!text-white hover:!bg-main !mt-0 !h-15">
                    Contact us
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="relative bg-main rounded-b-3xl">
            <div className="w-full flex items-center justify-center bg-main rounded-t-3xl absolute -top-[28px]">
              <span className="text-center flex items-center gap-2 text-white text-sm font-semibold py-1">
                Most Popular
                <BiSolidAlarm />
              </span>
            </div>
            <div className="flex flex-col justify-between p-8 bg-white border-main border-2 rounded-3xl sm:items-center h-fit">
              <div className="text-center">
                <div className="text-lg font-bold text-main uppercase">
                  Premium
                </div>
                <div className="flex items-center justify-center mt-2">
                  <div className="mr-1 text-5xl font-extrabold relative text-dark">
                    {getCurrencySymbol(currency)}

                    {convertPrice(prices.premium).toFixed(0)}
                  </div>
                  <div className="text-light font-medium">/ mo</div>
                </div>
                <hr className="w-full my-5 border" />
                <div className="mt-2 space-y-3 text-left">
                  <div className="text-dark font-bold flex items-center gap-1">
                    <BsCheck className="text-2xl text-main" />
                    <div>Mortgage Calculator</div>
                  </div>
                  <div className="flex items-center gap-1 text-dark font-bold">
                    <BsCheck className="text-2xl text-main" />
                    <div>No Set Up Fee</div>
                  </div>
                  <div className="flex items-center gap-1 text-dark font-bold">
                    <BsCheck className="text-2xl text-main" />
                    <div>5 x Emails (5GB)</div>
                  </div>
                  <div className="flex items-center gap-1 text-dark font-bold">
                    <BsCheck className="text-2xl text-main" />
                    <div>Unlimited Agents</div>
                  </div>
                  <div className="flex items-center gap-1 text-dark font-bold">
                    <BsCheck className="text-2xl text-main" />
                    <div>Unlimited Listings</div>
                  </div>
                  <div className="flex items-center gap-1 text-dark font-bold">
                    <BsCheck className="text-2xl text-main" />
                    <div>Social Media Integration</div>
                  </div>
                  <div className="flex items-center gap-1 text-dark font-bold">
                    <BsCheck className="text-2xl text-main" />
                    <div>Compare Properties</div>
                  </div>
                </div>
              </div>
              <div className="w-full mt-4">
                <NavLink to="/get-started">
                  <button className="cta !rounded-2xl !bg-main w-full">
                    Contact us
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiPricingTable;
