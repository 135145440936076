import React from "react";
import "../styles/Home.css";
import Highlight from "../img/highlight.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import Background from "../img/bg.jpg";
import Hero from "../img/hero.png";
import Slant from "../img/slant.svg";
import CtaButton from "../components/CtaButton";
import LogoCloud from "../components/LogoCloud";
import ScrollToHash from "../components/ScrollToHash";
import Contact from "../components/Contact";
import { InlineWidget } from "react-calendly";
import Offer from "../components/Offer";
import Steps from "../components/Steps";
import About from "../components/About";
import Products from "../components/Products";
import Magnet from "../components/Magnet";
const Home = () => {
  return (
    <>
      <ScrollToHash />
      <div className="relative mb-16">
        <img
          src={Background}
          className="absolute inset-0 object-cover w-full h-full"
          alt=""
        />
        <div className="absolute inset-0 object-cover w-full h-full"></div>
        <div className="relative bg-black bg-opacity-[90%]">
          <img
            src={Slant}
            className="absolute bottom-0 z-10 noselect "
            draggable="false"
            alt=""
          />
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 max-h-[730px] lg:mb-0 mb-[150px]">
            <div className="grid lg:grid-cols-2 mt-10 lg:mt-20 text-white ">
              <div className="space-y-7 mb-10">
                <div className="lg:mt-16">
                  <h1 className="capitalize text-center lg:text-left text-6xl">
                    Grow your <span className="text-main">Real estate</span>{" "}
                    business
                  </h1>
                </div>
                <div>
                  <p className="pb-3 text-lg text-white/60 max-w-lg text-center lg:text-left">
                    Maximize your potential with our high-conversion
                    real estate products designed to attract and engage clients
                  </p>
                </div>
                <div className="flex items-center gap-10 w-full lg:w-fit">
                  <CtaButton to="/get-started" />
                </div>
              </div>
              <div className="z-20">
                <div className="p-3 border-white/70 w-full h-fit">
                  <img src={Hero} className="" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Steps />
      <Products />
      <Magnet />
      <Contact to="get-started" />
    </>
  );
};

export default Home;
