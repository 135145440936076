import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

// components
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

// pages
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Demo from "./pages/Demo";
import CoAgent from "./pages/CoAgent";
import Website from "./pages/Website";

// funnel
import Funnel from "./funnel/Funnel";
import StrategyForm from "./funnel/form/StrategyForm";

const App = () => {
  const location = useLocation();
  const funnel = location.pathname === "/funnel" || "/strategy-session";

  return (
    <>
      {funnel && <Navbar />}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/funnel" element={<Funnel />} />
        <Route exact path="/strategy-session" element={<StrategyForm />} />
        <Route exact path="/websites" element={<Website />} />
        <Route exact path="/ai" element={<CoAgent />} />
        <Route exact path="/get-started" element={<Demo />} />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
      {funnel && <Footer />}
    </>
  );
};

export default App;
