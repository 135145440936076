import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { FaWandMagicSparkles } from "react-icons/fa6";
import Logo from "../img/primary.svg";
import Logo2 from "../img/secondary.svg";
import "../styles/Nav.css";
import { PiPhoneBold, PiPhoneFill } from "react-icons/pi";
import Flag from "react-world-flags"; // Import the flag component
const Navbar = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [activeDropdownItem, setActiveDropdownItem] = useState("");
  const [countryCode, setCountryCode] = useState("US"); // Default to US
  const navLinks = [{ to: "/", label: "Home" }];

  const [navClick, setNavClick] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navClick]);

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  // Mock function to get the user's country code
  useEffect(() => {
    // Replace this with your actual logic to get the user's country code
    const getUserCountryCode = async () => {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      setCountryCode(data.country_code);
    };

    getUserCountryCode();
  }, []);
  return (
    <>
      <nav
        className={`text-white font-semibold top-0 fixed z-50 w-full transition-all duration-300 ${
          isVisible ? "bg-[#191919] backdrop-blur-lg" : ""
        }`}
      >
        <div className="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="relative flex items-center justify-between py-3 px-4 md:px-0">
            <div className="flex items-center gap-5">
              <NavLink to="/" onClick={() => setNavClick(!navClick)}>
                <img src={Logo2} width={144} alt="" />
              </NavLink>
              <Flag code={countryCode} height="16" width="24" />
            </div>
            <ul className="flex items-center hidden space-x-8 lg:flex">
              {navLinks.map((link, index) => (
                <NavLink
                  className={`nav-link ${
                    location.pathname === link.to ||
                    (link.to === "#" && location.hash)
                      ? "active-link"
                      : ""
                  }`}
                  key={index}
                  to={link.to}
                  onClick={() => setNavClick(!navClick)}
                >
                  {link.label}
                </NavLink>
              ))}
              <li
                className="relative flex items-center"
                onMouseEnter={() => setIsServicesOpen(true)}
                onMouseLeave={() => setIsServicesOpen(false)}
              >
                <div className="flex flex-col">
                  <button
                    className="nav-link flex items-center gap-2"
                    onClick={() => setIsServicesOpen(!isServicesOpen)}
                  >
                    Services
                    <FaChevronDown
                      className={`transition-transform duration-300 text-sm ${
                        isServicesOpen ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                  {isServicesOpen && (
                    <div className="absolute top-full w-32 -right-2 backdrop-blur mt-0 pt-5 rounded-lg">
                      <div className="rounded bg-[#191919] overflow-hidden">
                        <NavLink
                          className={`block px-4 py-2 hover:bg-white/5 border-b border-white/5 ${
                            location.pathname === "/websites" ||
                            activeDropdownItem === "/websites"
                              ? "active-link"
                              : ""
                          }`}
                          to="/websites"
                          onClick={() => {
                            setNavClick(!navClick);
                          }}
                        >
                          Websites
                        </NavLink>
                        <NavLink
                          className={`block w-full px-4 py-2 hover:bg-white/5 flex items-center ${
                            location.pathname === "/ai" ||
                            activeDropdownItem === "/ai"
                              ? "active-link "
                              : ""
                          }`}
                          to="/ai"
                          onClick={() => {
                            setNavClick(!navClick);
                          }}
                        >
                          <FaWandMagicSparkles className="mr-1" />
                          AI Agent
                        </NavLink>
                      </div>
                    </div>
                  )}
                </div>
              </li>
              <li>
                <NavLink
                  to="/get-started"
                  onClick={() => setNavClick(!navClick)}
                >
                  <button className="cta flex items-center gap-3">
                    {/* <span className="text-[22px]">
                      {location.pathname === "/get-started" ? (
                        <PiPhoneFill />
                      ) : (
                        <PiPhoneBold />
                      )}
                    </span> */}
                    Get Started
                  </button>
                </NavLink>
              </li>
            </ul>
            <div className="lg:hidden">
              <button
                aria-label="Open Menu"
                title="Open Menu"
                className="p-2 -mr-1 transition duration-200 focus:outline-none focus:shadow-outline"
                onClick={() => setIsMenuOpen(true)}
              >
                <svg className="w-5 text-white" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  />
                </svg>
              </button>
              {isMenuOpen && (
                <div className="absolute top-0 left-0 w-full">
                  <div className="px-4 py-3 bg-[#191919] rounded-b-3xl shadow-sm transition-transform duration-300 ease-in-out transform translate-y-0 absolute w-full">
                    <div className="flex items-center justify-between pb-4 border-b border-white/5 mb-4">
                      <div className="flex items-center gap-5">
                        <NavLink to="/" onClick={() => setNavClick(!navClick)}>
                          <img src={Logo2} width={144} alt="" />
                        </NavLink>
                        <Flag code={countryCode} height="16" width="24" />
                      </div>
                      <div>
                        <button
                          aria-label="Close Menu"
                          title="Close Menu"
                          className="p-2 -mt-2 -mr-2 transition duration-200 focus:outline-none focus:shadow-outline"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <svg className="w-5 text-white" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <nav>
                      <ul className="space-y-6">
                        <li className="flex flex-col">
                          {navLinks.map((link, index) => (
                            <NavLink
                              className={`text-white border-b border-white/5 px-5 py-3 ${
                                location.pathname === link.to ||
                                (link.to === "#" && location.hash)
                                  ? "!text-main"
                                  : ""
                              }`}
                              key={index}
                              to={link.to}
                              onClick={() => {
                                setNavClick(!navClick);
                                setIsMenuOpen(false);
                              }}
                            >
                              {link.label}
                            </NavLink>
                          ))}
                          <div>
                            <button
                              className="text-white px-5 py-3 w-full text-left flex items-center gap-2 border-b border-white/5"
                              onClick={() => setIsServicesOpen(!isServicesOpen)}
                            >
                              Services
                              <FaChevronDown
                                className={`transition-transform duration-300 text-sm ${
                                  isServicesOpen ? "rotate-180" : ""
                                }`}
                              />
                            </button>
                            {isServicesOpen && (
                              <div className="flex flex-col">
                                <NavLink
                                  className={`text-white border-transparent px-5 py-3 ${
                                    location.pathname === "/websites" ||
                                    activeDropdownItem === "/websites"
                                      ? "!text-main"
                                      : ""
                                  }`}
                                  to="/websites"
                                  onClick={() => {
                                    setNavClick(!navClick);
                                    setIsMenuOpen(false);
                                  }}
                                >
                                  Websites
                                </NavLink>
                                <NavLink
                                  className={`text-white flex items-center w-full border-transparent px-5 py-3 ${
                                    location.pathname === "/ai" ||
                                    activeDropdownItem === "/ai"
                                      ? "!border-secondary"
                                      : ""
                                  }`}
                                  to="/ai"
                                  onClick={() => {
                                    setNavClick(!navClick);
                                    setIsMenuOpen(false);
                                  }}
                                >
                                  AI Agent
                                </NavLink>
                              </div>
                            )}
                          </div>
                        </li>
                        <li>
                          <NavLink
                            to="/get-started"
                            onClick={() => {
                              setNavClick(!navClick);
                              setIsMenuOpen(false);
                            }}
                          >
                            <button className="cta flex items-center gap-3 !w-full justify-center !rounded-2xl">
                              Get Started
                            </button>
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
